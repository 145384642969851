import type {AsyncNamespace, OttoNexus} from "@otto-ec/global-resources/nexus";
import type { Sharing } from "./sharing"
import type { Global } from "./global";

declare global {
    interface NexusInterfaces {
        /**
 *
 */
        apps: {
            sharing: AsyncNamespace<Sharing>,
            global: AsyncNamespace<Global>
        }
    }
}

export type apps = OttoNexus["apps"]

export const apps: OttoNexus["apps"] = otto.apps